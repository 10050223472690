




























import {Component, Vue} from 'vue-property-decorator';
import appType, {AppTypes} from "@/app-types";
import {PaymentChannel} from "@/repositories/company/payment-repository";
import MypageContainer from "@/views/common/mypage/MypageContainer.vue";

@Component({
  components: {MypageContainer}
})
export default class extends Vue {
    private get isAppTypeSpec():boolean {
      return appType === AppTypes.Spec;
    }

    public get showInvoices() {
      const s = this.$auth.user.getSubscription(appType);
      if (!s) return false;
      return s.paymentChannel !== PaymentChannel.Invoice;
    }

  }
